<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-06-22 15:29:35
 * @LastEditors: Seven
 * @LastEditTime: 2021-06-22 15:30:36
-->
<template>
	<div class="hq-title">
		<div class="bg"></div>
		<p class="text">
			<slot></slot>
		</p>
	</div>
</template>

<script>
export default {
	name: "HqTitle",
}
</script>

<style lang="scss" scoped>
.hq-title {
	display: flex;
	margin-bottom: 24px;

	.bg {
		width: 5px;
		height: 24px;
		background: #1677ff;
		border-radius: 3px;
		margin-right: 13px;
	}

	.text {
		font-size: 18px;
		color: #333;
		line-height: 24px;
	}
}
</style>